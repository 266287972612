import React from 'react';
import { Typography, Paper } from '@mui/material';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Paper elevation={2} style={{ color: "gray", position: "fixed", bottom: 0, alignContent: "center" }}>
      <Typography variant="body2" align="center">
        &copy; {currentYear} Days Enterprise
      </Typography>
    </Paper>
  );
};

export default Footer;
