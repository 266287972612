import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Standings from './pages/Standings';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Schedule from './pages/Schedule';
import Team from './pages/Team';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { UserAuthContextProvider, useUserAuth } from "./context/UserAuthContext";
import ProtectedRoute from './components/Routing/ProtectedRoute';
import UserManagement from './pages/admin/UserManagement';
import UserPreferences from './pages/UserPreferences';
import AdminProtectedRoute from './components/Routing/AdminProtectedRoute';
import UserProtectedRoute from './components/Routing/UserProtectedRoute';
import TeamsManagement from './pages/admin/TeamsManagement';
import CalendarPage from './pages/admin/CalendarManagement';
import MatchupPage from './pages/admin/MatchupManagement';

export interface IApplicationProps { }

const App: React.FunctionComponent<IApplicationProps> = (props) => {

  return (

    <BrowserRouter>
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Home" element={<ProtectedRoute role="none"><Navbar /><Home /></ProtectedRoute>} />
          <Route path="/Standings" element={<UserProtectedRoute><Navbar /><Standings /></UserProtectedRoute>} />
          <Route path="/Schedule" element={<UserProtectedRoute><Navbar /><Schedule /></UserProtectedRoute>} />
          <Route path="/Team" element={<UserProtectedRoute><Navbar /><Team /></UserProtectedRoute>} />
          <Route path="/CalendarManagement" element={<AdminProtectedRoute><Navbar /><CalendarPage /></AdminProtectedRoute>} />
          <Route path="/MatchupManagement" element={<AdminProtectedRoute><Navbar /><MatchupPage /></AdminProtectedRoute>} />
          <Route path="/TeamsManagement" element={<AdminProtectedRoute><Navbar /><TeamsManagement /></AdminProtectedRoute>} />
          <Route path="/Users" element={<AdminProtectedRoute><Navbar /><UserManagement /></AdminProtectedRoute>} />
          <Route path="/UserPreferences" element={<ProtectedRoute role="none"><Navbar /><UserPreferences /></ProtectedRoute>} />
        </Routes>
      </UserAuthContextProvider>
      <Footer />
    </BrowserRouter>
  );
};

export default App;