import React, { useState } from 'react';
import { Card, CardContent, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CompleteGoalieGameStats, LineupStats, Matchup, PlayerGameStats } from '../typing';
import { ExpandMore } from '@mui/icons-material';

interface GameCardProps {
  matchup: Matchup;
}

const GameCard: React.FC<GameCardProps> = ({ matchup }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const index = [0, 1, 2];
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const countSumOf = (team: LineupStats, line: number) => {
    let sumG = team?.c[line].g + team.rw[line].g + team.lw[line].g + team.d1[line].g + team.d2[line].g;
    let sumPpg = team?.c[line].ppg + team.rw[line].ppg + team.lw[line].ppg + team.d1[line].ppg + team.d2[line].ppg;
    let sumShg = team?.c[line].shg + team.rw[line].shg + team.lw[line].shg + team.d1[line].shg + team.d2[line].shg;
    let sumA = team.c[line].a + team.rw[line].a + team.lw[line].a + team.d1[line].a + team.d2[line].a;
    let sumPpa = team.c[line].ppa + team.rw[line].ppa + team.lw[line].ppa + team.d1[line].ppa + team.d2[line].ppa;
    let sumSha = team.c[line].sha + team.rw[line].sha + team.lw[line].sha + team.d1[line].sha + team.d2[line].sha;
    let sumP = team.c[line].penaltyMinutes + team.rw[line].penaltyMinutes + team.lw[line].penaltyMinutes + team.d1[line].penaltyMinutes + team.d2[line].penaltyMinutes;
    let model =
    {
      g: sumG,
      ppg: sumPpg,
      shg: sumShg,
      totalGoals: (sumG + sumPpg + sumShg),
      a: sumA,
      ppa: sumPpa,
      sha: sumSha,
      totalAssists: (sumA + sumPpa + sumSha),
      penaltyMinutes: sumP
    };

    return model;
  };

  const cellStyle = {
    fontSize: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)', // Set border to the smallest possible size
  };

  const centerCellStyle = {
    ...cellStyle,
    textAlign: 'center' as const, // Specify textAlign as 'center'
  };
  const yellowStyle = {
    ...cellStyle,
    textAlign: 'center' as const, // Specify textAlign as 'center'
    background: 'yellow',
    fontWeight: 'bold',
  };

  const greyStyle = {
    ...cellStyle,
    textAlign: 'center' as const, // Specify textAlign as 'center'
    background: 'grey'
  };

  const lightGreyStyle = {
    ...cellStyle,
    textAlign: 'center' as const, // Specify textAlign as 'center'
    background: 'rgba(0, 0, 0, 0.12)',
    fontWeight: 'bold',
  };
  const boldStyle = {
    ...cellStyle,
    textAlign: 'center' as const, // Specify textAlign as 'center'
    fontWeight: 'bold',
  };

  const boldUnderStyle = {
    ...cellStyle,
    textAlign: 'center' as const, // Specify textAlign as 'center'
    fontWeight: 'bold',
    textDecoration: 'underline',
  };

  function PlayerStatsTable(team: LineupStats, title: string, index: number) {
    return (
      <TableContainer style={{ overflow: 'auto' }}>
        <Typography>{title}</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={boldStyle}>L{index + 1}</TableCell>
              <TableCell style={boldStyle}>Name</TableCell>
              <TableCell style={boldStyle}>G</TableCell>
              <TableCell style={boldStyle}>PPG</TableCell>
              <TableCell style={boldStyle}>SHG</TableCell>
              <TableCell style={boldStyle}>A</TableCell>
              <TableCell style={boldStyle}>PPA</TableCell>
              <TableCell style={boldStyle}>SHA</TableCell>
              <TableCell style={boldStyle}>PM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderPlayerRow(team?.c[index], 'C', index)}
            {renderPlayerRow(team?.rw[index], 'RW', index)}
            {renderPlayerRow(team?.lw[index], 'LW', index)}
            {renderPlayerRow(team?.d1[index], 'D1', index)}
            {renderPlayerRow(team?.d2[index], 'D2', index)}
            <TableRow>
              <TableCell style={boldUnderStyle} colSpan={2}>Sous-total</TableCell>
              <TableCell style={lightGreyStyle}>{countSumOf(team, index).g}</TableCell>
              <TableCell style={lightGreyStyle}>{countSumOf(team, index).ppg}</TableCell>
              <TableCell style={lightGreyStyle}>{countSumOf(team, index).shg}</TableCell>
              <TableCell style={lightGreyStyle}>{countSumOf(team, index).a}</TableCell>
              <TableCell style={lightGreyStyle}>{countSumOf(team, index).ppa}</TableCell>
              <TableCell style={lightGreyStyle}>{countSumOf(team, index).sha}</TableCell>
              <TableCell style={lightGreyStyle}>{countSumOf(team, index).penaltyMinutes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={boldUnderStyle} colSpan={2}>Total</TableCell>
              <TableCell style={yellowStyle} colSpan={3}>{countSumOf(team, index).totalGoals}</TableCell>
              <TableCell style={yellowStyle} colSpan={3}>{countSumOf(team, index).totalAssists}</TableCell>
              <TableCell style={greyStyle}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderPlayerRow(playerGameStats: PlayerGameStats, position: string, index: number) {
    return (
      <TableRow key={`${position}-${index}`}>
        <TableCell style={boldStyle}>{position}</TableCell>
        <TableCell style={cellStyle}>{playerGameStats.playerName}</TableCell>
        <TableCell style={centerCellStyle}>{playerGameStats.g}</TableCell>
        <TableCell style={centerCellStyle}>{playerGameStats.ppg}</TableCell>
        <TableCell style={centerCellStyle}>{playerGameStats.shg}</TableCell>
        <TableCell style={centerCellStyle}>{playerGameStats.a}</TableCell>
        <TableCell style={centerCellStyle}>{playerGameStats.ppa}</TableCell>
        <TableCell style={centerCellStyle}>{playerGameStats.sha}</TableCell>
        <TableCell style={centerCellStyle}>{playerGameStats.penaltyMinutes}</TableCell>
      </TableRow>
    );
  }

  function generateGridWithTable(title: string, data: CompleteGoalieGameStats[]) {
    return (
      <TableContainer>
        <Typography>{title}</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={boldStyle}>G</TableCell>
              {data.map((item, index) => (
                <TableCell key={index} style={boldStyle} colSpan={3}>
                  {item.playerName}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell style={greyStyle}></TableCell>
              {data.map((item, index) => (
                <React.Fragment key={index}>
                  <TableCell style={boldStyle}>SA</TableCell>
                  <TableCell style={boldStyle}>GA</TableCell>
                  <TableCell style={boldStyle}>%</TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[0, 1, 2, 3].map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell style={row === 3 ? boldUnderStyle : boldStyle}>{row === 3 ? 'Total' : `${row}`}</TableCell>
                {data.map((item, dataIndex) => (
                  <React.Fragment key={dataIndex}>
                    <TableCell style={row === 3 ? lightGreyStyle : centerCellStyle}>{item.stats[row]?.goals}</TableCell>
                    <TableCell style={row === 3 ? lightGreyStyle : centerCellStyle}>{item.stats[row]?.shots}</TableCell>
                    <TableCell style={row === 3 ? lightGreyStyle : centerCellStyle}>{item.stats[row]?.savePercentage}</TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (

    <Card>
      <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>
          {matchup.homeTeamName ?? ''} vs. {matchup.awayTeamName ?? ''}
        </Typography>
        <IconButton
          aria-label="expand"
          onClick={toggleExpand}
          size="small"
        >
          <ExpandMore />
        </IconButton>
      </CardContent>
      <Collapse in={isExpanded}>
        <CardContent>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {matchup?.homeTeam && matchup?.homeTeam !== null ? (
              <Grid key={`line1-home`} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {PlayerStatsTable(matchup.homeTeam, "Home", 0)}
              </Grid>
            ) : null}
            {matchup?.awayTeam && matchup?.awayTeam !== null ? (
              <Grid key={`line1-away`} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {PlayerStatsTable(matchup.awayTeam, "Away", 0)}
              </Grid>
            ) : null}
            {matchup?.homeTeam && matchup?.homeTeam !== null ? (
              <Grid key={`line2-home`} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {PlayerStatsTable(matchup.homeTeam, "Home", 1)}
              </Grid>
            ) : null}
            {matchup?.awayTeam && matchup?.awayTeam !== null ? (
              <Grid key={`line2-away`} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {PlayerStatsTable(matchup.awayTeam, "Away", 1)}
              </Grid>
            ) : null}
            {matchup?.homeTeam && matchup?.homeTeam !== null ? (
              <Grid key={`line3-home`} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {PlayerStatsTable(matchup.homeTeam, "Home", 2)}
              </Grid>
            ) : null}
            {matchup?.awayTeam && matchup?.awayTeam !== null ? (
              <Grid key={`line3-away`} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {PlayerStatsTable(matchup.awayTeam, "Away", 2)}
              </Grid>
            ) : null}
            {matchup?.homeTeam && matchup?.homeTeam !== null ? (
              <Grid key={'goalie-home'} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {generateGridWithTable('Home', matchup?.homeTeam?.g)}
              </Grid>
            ) : null}
            {matchup?.awayTeam && matchup?.awayTeam !== null ? (
              <Grid key={'goalie-away'} item xs={12} md={6} style={{ overflow: 'auto' }}>
                {generateGridWithTable('Away', matchup?.awayTeam?.g)}
              </Grid>
            ) : null}

          </Grid>

          {/* Add more stats as needed */}
        </CardContent>
      </Collapse>
    </Card >
  );
};
export default GameCard;