import { Box, Button, Divider, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { db } from "../../firebaseSetup";
import { Matchup, Settings, Week, f_Team } from "../../typing";
import { customSort } from "../../helpers";
import GameCard from "../../components/GameCard";

const MatchupPage = () => {
    const [matchups, setMatchups] = useState<Matchup[]>([]);
    const [weeks, setWeeks] = useState<Week[]>([]);
    const [teams, setTeams] = useState<f_Team[]>([]);
    const [settings, setSettings] = useState<Settings>();
    const [gameNumber, setGameNumber] = useState<string>('');
    const [selectedWeek, setSelecteWeek] = useState<string>('');
    const [selectedHome, setSelecteHome] = useState<string>('');
    const [selectedAway, setSelecteAway] = useState<string>('');
    const [buttonColor, setButtonColor] = useState<'primary' | 'success' | 'error'>('primary');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch settings first
                const settingsDocRef = await db.collection('current-settings').doc('default');
                const settingsDoc = await settingsDocRef.get();

                if (settingsDoc.exists) {
                    const settingsData = {
                        id: settingsDoc.id,
                        ...settingsDoc.data(),
                    } as Settings;

                    setSettings(settingsData);

                    // Once settings are fetched, fetch teams and weeks
                    const teamsSnapshot = await db.collection('fantasy-teams').get();
                    const teamData = teamsSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })) as f_Team[];
                    setTeams(teamData);

                    const weeksSnapshot = await db
                        .collection('fantasy-weeks')
                        .where('seasonId', '==', settingsData?.seasonId)
                        .get();

                    const weeksData = weeksSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })) as Week[];
                    weeksData.sort((a, b) => customSort((item) => item.number, a, b));
                    setWeeks(weeksData);

                    const matchupsSnapshot = await db
                        .collection('matchups')
                        .where('seasonId', '==', settingsData?.seasonId)
                        .get();

                    const matchupsData = matchupsSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })) as Matchup[];
                    setMatchups(matchupsData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleWeekSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        const weekId = event.target.value as string;
        setSelecteWeek(weekId);
    };

    const handleHomeTeamSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        const teamId = event.target.value as string;
        setSelecteHome(teamId);
    };

    const handleAwayTeamSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        const teamId = event.target.value as string;
        setSelecteAway(teamId);
    };

    const handleGameNumberSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        const gameNumber = event.target.value as string;
        setGameNumber(gameNumber);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const matchupsCollection = db.collection('matchups');

        const newMatchupData = {
            awayTeamId: selectedAway,
            awayTeamName: teams.find((team) => team.id === selectedAway)?.name ?? '',
            homeTeamId: selectedHome,
            homeTeamName: teams.find((team) => team.id === selectedHome)?.name ?? '',
            fantasyWeekId: selectedWeek,
            seasonId: settings?.seasonId ?? '',
            gameNumber: parseInt(gameNumber),
            homeTeam: null,
            awayTeam: null
        };

        matchupsCollection
            .add(newMatchupData)
            .then((docRef) => {
                const newMatchupId = docRef.id;

                const updatedMatchups = [...matchups];

                updatedMatchups.push({
                    id: newMatchupId,
                    ...newMatchupData,
                });
                setMatchups(updatedMatchups);

                setButtonColor('success');
                setTimeout(() => {
                    setButtonColor('primary');
                }, 5000);
            })
            .catch((error) => {

                console.error('Error adding matchup document: ', error);
                setButtonColor('error');
                setTimeout(() => {
                    setButtonColor('primary');
                }, 5000);
            });

    };

    const matchupsByWeekAndGame: Record<string, Record<string, Matchup[]>> = matchups.reduce((acc, matchup) => {
        const { fantasyWeekId, gameNumber } = matchup;

        // Create a new week object if it doesn't exist in the accumulator
        if (!acc[fantasyWeekId]) {
            // Use type assertion here to tell TypeScript the expected structure
            acc[fantasyWeekId] = {};
        }

        // Create a new game number object if it doesn't exist in the week object
        if (!acc[fantasyWeekId][gameNumber]) {
            // Use type assertion here to tell TypeScript the expected structure
            acc[fantasyWeekId][gameNumber] = [] as Matchup[];
        }

        // Push the matchup into the corresponding game number
        acc[fantasyWeekId][gameNumber].push(matchup);

        return acc;
    }, {} as Record<string, Record<string, Matchup[]>>);

    return (
        <Box
            paddingY={{ xs: '5px', sm: '10px' }}
            paddingX={{ xs: '5pxpx', sm: '10px' }}
        >
            <Typography variant="h6">Season - {settings?.seasonId}</Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <InputLabel id="select-week">Week</InputLabel>
                    <Select labelId="select-week"
                        label={"Week"}
                        value={selectedWeek}
                        onChange={handleWeekSelect as any}
                        fullWidth
                        sx={{ marginTop: '4px' }}
                    >
                        <MenuItem key={`week-empty`} value={''}>None</MenuItem>
                        {weeks.map((week) => (
                            <MenuItem key={week.id} value={week.id}>
                                {week.number}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel id="select-game">Game #</InputLabel>
                    <Select labelId="select-game"
                        label="Game #"
                        value={gameNumber}
                        onChange={handleGameNumberSelect as any}
                        fullWidth
                        sx={{ marginTop: '4px' }}
                    >
                        <MenuItem key={`gamenumber-empty`} value={''}>None</MenuItem>
                        <MenuItem key={`gamenumber-1`} value={'1'}>1</MenuItem>
                        <MenuItem key={`gamenumber-2`} value={'2'}>2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel id="select-home">Home</InputLabel>
                    <Select labelId="select-home"
                        label={"Home"}
                        value={selectedHome}
                        onChange={handleHomeTeamSelect as any}
                        fullWidth
                        sx={{ marginTop: '4px' }}
                    >
                        <MenuItem key={`home-empty`} value={''}>None</MenuItem>
                        {teams.map((team) => (
                            <MenuItem key={team.id + `home`} value={team.id}>
                                {team.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel id="select-away">Away</InputLabel>
                    <Select labelId="select-away"
                        label={"Away"}
                        value={selectedAway}
                        onChange={handleAwayTeamSelect as any}
                        fullWidth
                        sx={{ marginTop: '4px' }}
                    >
                        <MenuItem key={`away-empty`} value={''}>None</MenuItem>
                        {teams.map((team) => (
                            <MenuItem key={team.id + `away`} value={team.id}>
                                {team.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            <Button onClick={handleSubmit} type="submit" variant="contained" color={buttonColor} style={{ marginTop: '10px' }}>
                Add matchup
            </Button>

            <Box
                paddingY={{ xs: '5px', sm: '10px' }}
                paddingX={{ xs: '5pxpx', sm: '10px' }}
            >
                <Typography variant="h6">Matchups</Typography>
                {Object.entries(matchupsByWeekAndGame).map(([week, gameData]) => (
                    <div key={week}>
                        <Divider>Week {week}</Divider>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {Object.entries(gameData).map(([gameNumber, matchups]) => (
                                <Grid key={gameNumber} item xs={12} md={12}>
                                    <Typography>Game # {gameNumber}</Typography>
                                    {matchups.map((matchup) => (
                                        <Grid key={matchup.id} item style={{ padding: '5px' }}>
                                            <GameCard key={matchup.id} matchup={matchup} />
                                        </Grid>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ))}

            </Box>
        </Box >
    );
};
export default MatchupPage;