import React, { useEffect, useState } from 'react';
import { Box, TableCell, TableRow, TableHead, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { db } from '../../firebaseSetup';
import { customSort } from '../../helpers';
import { Game, Week, Season } from '../../typing';
import { CircularProgress } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { doc, setDoc } from 'firebase/firestore';


const CalendarPage = () => {
  const [selectedSeason, setSelectedSeason] = useState<string | ''>(''); // State to store the selected se
  const [games, setGames] = useState<Game[]>([]);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [weeks, setWeeks] = useState<Week[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedWeekNumber, setSelectedWeekNumber] = useState<string | null>(null);

  async function fetchGames(selectedSeason: string, usefresh: boolean) {
    setLoading(true);
    try {
      const gamesSnapshot = await db.collection('games').where('seasonId', '==', selectedSeason).get();

      const gamesData = gamesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Game[];

      gamesData.sort((a, b) => customSort((item) => item.gameId, a, b));
      setGames(gamesData);

    } catch (error) {
      // Handle error
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchWeeks(selectedSeason: string, usefresh: boolean) {
    // Your code to fetch weeks here using the selectedSeason
    const weeksSnapshot = await db.collection('fantasy-weeks').where('seasonId', '==', selectedSeason).get();

    const weeksData = weeksSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Week[];

    weeksData.sort((a, b) => customSort((item) => item.number, a, b));

    setWeeks(weeksData);
  }

  async function fetchSeasons(usefresh: boolean) {
    // Your code to fetch seasons here
    const seasonsCollection = await db.collection('fantasy-seasons').get({ source: 'cache' });

    const seasonsData = seasonsCollection.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Season[];
    setSeasons(seasonsData);
  }

  const fetchData = (usefresh: boolean) => {
    fetchWeeks(selectedSeason, usefresh)
      .then((weeksData) => {
        // Handle weeks data
      })
      .catch((error) => {
        // Handle error
      });

    // Call the fetch functions to fetch and set data with the selectedSeason
    fetchGames(selectedSeason, usefresh)
      .then((gamesData) => {
        // Handle games data
      })
      .catch((error) => {
        // Handle error
      });


    fetchSeasons(usefresh)
      .then((seasonsData) => {
        // Handle seasons data
      })
      .catch((error) => {
        // Handle error
      });
  };

  const handleAwayWeekNumberChange = async (event: string, gameId: string) => {
    const newWeekNumber = event;

    const gameRef = db.collection('games').doc(gameId);
    const previousGameDoc = await gameRef.get();
    const previousGameData = previousGameDoc.data() as Game;

    let weekNumber = previousGameData.awayTeamGameWeekId;
    if (newWeekNumber && newWeekNumber !== '') {
      weekNumber = newWeekNumber;
    }
    if (weekNumber && weekNumber !== '') {
      setSelectedWeekNumber(weekNumber);
    }

    const weekRef = db.collection('fantasy-weeks').doc(`${selectedSeason}-${weekNumber}`);
    const weekDoc = await weekRef.get();
    const week = weekDoc.data() as Week;

    let number = newWeekNumber === '' ? 0 : 1;

    if (week.startDate && week.endDate && number !== 0) {
      const weekGames = await db.collection('games').where('gameDate', '>=', week.startDate).where('gameDate', '<', week.endDate).get();

      const weekGamesData = weekGames.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Game[];

      const homeTeamGameCount = weekGamesData.filter((weekGame) =>
        weekGame.homeTeamId === previousGameData.awayTeamId
        && weekGame.gameId !== gameId &&
        weekGame.homeTeamGameWeekId === weekNumber
      );
      const awayTeamGameCount = weekGamesData.filter((weekGame) =>
        weekGame.awayTeamId === previousGameData.awayTeamId
        && weekGame.gameId !== gameId &&
        weekGame.awayTeamGameWeekId === weekNumber
      );

      console.log(awayTeamGameCount);
      console.log(homeTeamGameCount);

      number = homeTeamGameCount.length + awayTeamGameCount.length + 1;
    }



    // Find the game in the 'games' array and update its 'awayWeekNumber'
    const updatedGames = games.map((game) => {
      if (game.id === gameId) {
        return {
          ...game,
          awayTeamGameWeekId: newWeekNumber,
          awayGameWeekNumber: number
        };
      }
      return game;
    });

    await gameRef.update({
      awayTeamGameWeekId: newWeekNumber,
      awayGameWeekNumber: number
    });

    setGames(updatedGames);
  };

  const updateWeekDates = async (weekNumber: string) => {

    const weekId = `${selectedSeason}-${weekNumber}`

    const gamesForWeek = games.filter((game) =>
      game.awayTeamGameWeekId === weekNumber ||
      game.homeTeamGameWeekId === weekNumber
    )

    const gameDates = gamesForWeek.map((game) => game.gameDate.toMillis());

    const min = new Date(Math.min(...gameDates));

    const max = new Date(Math.max(...gameDates))

    const weekRef = doc(db, 'fantasy-weeks', weekId);
    try {
      let needsUpdate = false;

      let updateFields: Record<string, any> = {};

      if (min) {
        updateFields.startDate = min;
        needsUpdate = true;
      }

      if (max) {
        updateFields.endDate = max;
        needsUpdate = true;
      }

      if (needsUpdate) {
        await setDoc(weekRef, updateFields, { merge: true });
      }

    } catch (error) {
      // Handle any errors that occur during the update
      console.error('Error updating week:', error);
    }
  };

  const handleHomeWeekNumberChange = async (event: string, gameId: string) => {
    const newWeekNumber = event;

    const gameRef = db.collection('games').doc(gameId);
    const previousGameDoc = await gameRef.get();
    const previousGameData = previousGameDoc.data() as Game;

    let weekNumber = previousGameData.homeTeamGameWeekId;
    if (newWeekNumber && newWeekNumber !== '') {
      weekNumber = newWeekNumber;
    }
    if (weekNumber && weekNumber !== '') {
      setSelectedWeekNumber(weekNumber);
    }
    const weekRef = db.collection('fantasy-weeks').doc(`${selectedSeason}-${weekNumber}`);
    const weekDoc = await weekRef.get();
    const week = weekDoc.data() as Week;

    let number = newWeekNumber === '' ? 0 : 1;

    if (week.startDate && week.endDate && number !== 0) {

      const weekGames = await db.collection('games').where('gameDate', '>=', week.startDate).where('gameDate', '<', week.endDate).get();

      const weekGamesData = weekGames.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Game[];

      const homeTeamGameCount = weekGamesData.filter((weekGame) =>
        weekGame.homeTeamId === previousGameData.homeTeamId
        && weekGame.gameId !== gameId &&
        weekGame.homeTeamGameWeekId === weekNumber
      );
      const awayTeamGameCount = weekGamesData.filter((weekGame) =>
        weekGame.awayTeamId === previousGameData.homeTeamId
        && weekGame.gameId !== gameId &&
        weekGame.awayTeamGameWeekId === weekNumber
      );

      number = homeTeamGameCount.length + awayTeamGameCount.length + 1;
    }

    // Find the game in the 'games' array and update its 'awayWeekNumber'
    const updatedGames = games.map((game) => {
      if (game.id === gameId) {
        return {
          ...game,
          homeTeamGameWeekId: newWeekNumber,
          homeGameWeekNumber: number
        };
      }
      return game;
    });

    await gameRef.update({
      homeTeamGameWeekId: newWeekNumber,
      homeGameWeekNumber: number
    });

    setGames(updatedGames);
  };

  useEffect(() => {
    if (selectedWeekNumber !== null) {
      updateWeekDates(selectedWeekNumber);
    }

    const gameCountsByTeamIdAndWeek: Record<string, Record<string, number>> = {};

    // Iterate through the games array
    games.forEach((game) => {
      // Function to update game counts for a specific teamId and weekId
      const updateTeamWeekStats = (teamId: string, weekId: string) => {
        if (!gameCountsByTeamIdAndWeek[teamId]) {
          // If the teamId key doesn't exist, initialize it
          gameCountsByTeamIdAndWeek[teamId] = {};
        }
        if (!gameCountsByTeamIdAndWeek[teamId][weekId]) {
          // If the weekId key doesn't exist, initialize it
          gameCountsByTeamIdAndWeek[teamId][weekId] = 1;
        } else {
          // If the weekId key already exists, increment the count
          gameCountsByTeamIdAndWeek[teamId][weekId]++;
        }
      };

      // Check if awayTeamId is defined and not empty, and awayTeamGameWeekId is not 0 or empty
      if (game.awayTeamId && game.awayTeamId !== '' && game.awayTeamGameWeekId !== '') {
        updateTeamWeekStats(game.awayTeamId, `week${game.awayTeamGameWeekId}`);
      }

      // Check if homeTeamId is defined and not empty, and homeTeamGameWeekId is not 0 or empty
      if (game.homeTeamId && game.homeTeamId !== '' && game.homeTeamGameWeekId !== '') {
        updateTeamWeekStats(game.homeTeamId, `week${game.homeTeamGameWeekId}`);
      }
    });

    // Now, gameCountsByTeamIdAndWeek contains the counts of games by teamId and weekId
    console.log(gameCountsByTeamIdAndWeek);

  }, [games]);

  useEffect(() => {
    if (selectedSeason === null) {
      return; // Do not fetch data until a season is selected
    }

    // Set loading to true when data fetching starts
    fetchData(true); // Initial data fetch
    /*
    // Set up an interval to refresh data every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(() => {
      fetchData(true); // Refresh data
    }, 60000); // 5 minutes in milliseconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);*/
  }, [selectedSeason]);

  const cellStyle = {
    fontSize: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)', // Set border to the smallest possible size
  };

  const centerCellStyle = {
    ...cellStyle,
    textAlign: 'center' as const, // Specify textAlign as 'center'
  };

  return (
    <Box
      paddingY={{ xs: '5px', sm: '10px' }}
      paddingX={{ xs: '5pxpx', sm: '10px' }}
    >
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <InputLabel>Season</InputLabel>
        <Select
          label="Season"
          value={selectedSeason}
          onChange={(e) => setSelectedSeason(e.target.value as string)}
        >
          {seasons.map((season) => (
            <MenuItem key={season.id} value={season.id}>
              {season.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <>
        <TableHead style={centerCellStyle}>
          <TableRow style={centerCellStyle}>
            <TableCell style={centerCellStyle}>Date</TableCell>
            <TableCell style={centerCellStyle}>Game ID</TableCell>
            <TableCell style={centerCellStyle}>Away Team</TableCell>
            <TableCell style={centerCellStyle}>Game #</TableCell>
            <TableCell style={centerCellStyle}>Away Team Week</TableCell>
            <TableCell style={centerCellStyle}>Home Team</TableCell>
            <TableCell style={centerCellStyle}>Game #</TableCell>
            <TableCell style={centerCellStyle}>Home Team Week</TableCell>
          </TableRow>
        </TableHead>
      </>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableVirtuoso
          style={{ height: 450, width: '100%', border: '1px solid black' }}
          data={games}
          itemContent={(index, game) => (
            <>
              <TableCell style={centerCellStyle}>{game.gameDate && game.gameDate.toDate().toLocaleString()}</TableCell>
              <TableCell style={centerCellStyle}>{game.gameId}</TableCell>
              <TableCell style={centerCellStyle}>{game.awayTeam}</TableCell>
              <TableCell style={{
                border: '1px solid black', width: '50px', textAlign: 'center',
                backgroundColor:
                  game.awayGameWeekNumber === 0 ? 'lightgrey' :
                    game.awayGameWeekNumber === 1 ? 'salmon' :
                      game.awayGameWeekNumber === 2 ? 'lightgreen' :
                        game.awayGameWeekNumber >= 3 ? 'red' :
                          'white' // Default background color 
              }}>{game.awayGameWeekNumber === 0 ? '' : game.awayGameWeekNumber}</TableCell>
              <TableCell style={{ border: '1px solid black', width: '200px', textAlign: 'center' }}>
                <Select
                  value={game.awayTeamGameWeekId || ''}
                  style={{ width: '184px' }}
                  onChange={(event) =>
                    handleAwayWeekNumberChange(event.target.value as string, game.id)
                  }
                >
                  <MenuItem value="">None</MenuItem>
                  {weeks.map((week) => (
                    <MenuItem key={week.id} value={week.number}>
                      {week.number}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell style={centerCellStyle}>{game.homeTeam}</TableCell>
              <TableCell style={{
                border: '1px solid black', width: '50px', textAlign: 'center',
                backgroundColor:
                  game.homeGameWeekNumber === 0 ? 'lightgrey' :
                    game.homeGameWeekNumber === 1 ? 'salmon' :
                      game.homeGameWeekNumber === 2 ? 'lightgreen' :
                        game.homeGameWeekNumber >= 3 ? 'red' :
                          'white' // Default background color 
              }}>{game.homeGameWeekNumber === 0 ? '' : game.homeGameWeekNumber}</TableCell>
              <TableCell style={centerCellStyle}>
                <Select
                  value={game.homeTeamGameWeekId || ''}
                  style={{ width: '184px' }}
                  onChange={(event) =>
                    handleHomeWeekNumberChange(event.target.value as string, game.id)
                  }
                >
                  <MenuItem value="">None</MenuItem>
                  {weeks.map((week) => (
                    <MenuItem key={week.id} value={week.number}>
                      {week.number}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </>
          )}
        />
      )
      }

    </Box >
  );
};

export default CalendarPage;