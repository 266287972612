import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Container,
  Select,
  MenuItem,
  Chip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Player } from '../typing';
import { Add, Delete } from '@mui/icons-material';

interface PlayerTableProps {
  players: Player[]; // Array of players
  onChangePosition: (player: Player, newPosition: string) => void;
  onRemovePlayer: (playerId: string) => void;
  onIsM25: (player: Player, isM25: boolean) => void;
}

const PlayerTable: React.FC<PlayerTableProps> = ({
  players,
  onChangePosition,
  onRemovePlayer,
  onIsM25
}) => {
  const positions = ['LW', 'C', 'RW', 'D', 'G'];
  const { t } = useTranslation();

  const handlePositionChange = (player: Player, newPosition: string) => {
    // Call the parent component's function to change the player's position
    onChangePosition(player, newPosition);
  };

  const handleTag = (player: Player, isM25: boolean) => {
    onIsM25(player, isM25);
  };

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{t("PlayerName")}</TableCell>
              <TableCell>{t("Position")}</TableCell>
              <TableCell>{t("Action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((player) => (
              <TableRow key={player.id}>
                <TableCell>
                  {player.id}
                </TableCell>
                <TableCell>
                  <>{player.fullName}</>
                  <Chip
                    key={player.id + 'm25'}
                    label='M25'
                    style={{ margin: '10px' }}
                    deleteIcon={player.isM25 ? <Delete /> : <Add />}
                    onDelete={(e) => handleTag(player, !player.isM25)}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={player.position}
                    onChange={(e) => handlePositionChange(player, e.target.value as string)}
                  >
                    {positions.map((position) => (
                      <MenuItem key={position} value={position}>
                        {position}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onRemovePlayer(player.id)}
                  >
                    {t("Remove")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PlayerTable;