import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from '@mui/material';
import { db } from '../firebaseSetup';
import { useTranslation } from 'react-i18next';
import { f_Team, Team, Player } from '../typing';

interface AddPlayerModalProps {
  open: boolean;
  onClose: () => void;
  team: f_Team; // Pass the list of teams to the modal
  onAddPlayer: (selectedTeamId: string, selectedPlayer: Player) => void;
}

const AddPlayerModal: React.FC<AddPlayerModalProps> = ({
  open,
  onClose,
  team,
  onAddPlayer,
}) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [selectedPlayer, setSelectedPlayer] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const playersSnapshot = await db
          .collection('players')
          .where('teamId', '==', selectedTeam)
          .orderBy('position') // Order by position
          .get({ source: 'cache' }); // Use cached data if available

        if (playersSnapshot.empty || playersSnapshot.size < 5) {
          // Data is not available in cache; fetch from the server
          const freshPlayersSnapshot = await db
            .collection('players')
            .where('teamId', '==', selectedTeam)
            .orderBy('position')
            .get();

          const playerData = freshPlayersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Player[];

          setPlayers(playerData);
        } else {
          // Data is available in cache; use it
          const playerData = playersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Player[];

          setPlayers(playerData);
        }
      } catch (error) {
        console.error('Error fetching players: ', error);
      }
    };

    if (selectedTeam) {
      // Fetch players only if a team is selected
      fetchPlayers();
    } else {
      // Clear the players list if no team is selected
      setPlayers([]);
    }
  }, [selectedTeam]); // Include selectedTeam in the dependency array


  const handleAddClick = () => {
    const selectedPlayerObject = players.find((player) => player.id === selectedPlayer);
    if (selectedPlayerObject) {
      onAddPlayer(team.id, selectedPlayerObject);
    }
    onClose();
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsSnapshot = await db.collection('teams').orderBy('name').get({ source: 'cache' });


        if (teamsSnapshot.empty || teamsSnapshot.size <= 1) {
          // Data is not available in cache; fetch from the server
          const teamsFreshSnapshot = await db.collection('teams').orderBy('name').get();
          const teamData = teamsFreshSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Team[];
          setTeams(teamData);
        } else {
          // Data is available in cache; use it
          const teamData = teamsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Team[];
          setTeams(teamData);
        }
      } catch (error) {
        console.error('Error fetching teams: ', error);
      }
    };

    fetchTeams();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("AddPlayer")}</DialogTitle>
      <DialogContent>
        <Select
          value={selectedTeam}
          onChange={(e) => setSelectedTeam(e.target.value as string)}
          fullWidth
        >
          <MenuItem value="">{t("SelectTeam")}</MenuItem>
          {teams.map((team) => (
            <MenuItem key={team.id} value={team.id}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedPlayer}
          onChange={(e) => setSelectedPlayer(e.target.value as string)}
          fullWidth
          disabled={!selectedTeam}
        >
          <MenuItem value="">{t("SelectPlayer")}</MenuItem>
          {players.map((player) => (
            <MenuItem key={player.id} value={player.id}>
              {player.fullName} - {player.position}
            </MenuItem>
          ))}
          {/* Fetch and populate players based on the selected team */}
          {/* You can use a similar approach as in TeamsManagementPage */}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleAddClick} color="primary" disabled={!selectedTeam || !selectedPlayer}>
          {t("Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlayerModal;