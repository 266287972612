import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  User,
  signInWithCustomToken,
} from "firebase/auth";
import { auth, db } from "../firebaseSetup";

interface UserAuthContextType {
  user: AuthUser | null; // Replace 'any' with the actual user type
  logIn: (email: string, password: string, rememberMe: boolean) => Promise<any>;
  logInWithToken: (token: string) => Promise<any>;
  signUp: (email: string, password: string) => Promise<any>;
  logOut: () => Promise<any>;
  googleSignIn: () => Promise<any>;
}

interface AuthUser extends User {
  displayName: string;
  language: string;
  role: string;
  teamId: string;
}

const userAuthContext = createContext<UserAuthContextType | undefined>(undefined);

export function UserAuthContextProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<AuthUser | null>(() => {
    const storedUserData = localStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  function storeUserData(userData: AuthUser) {
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  function logIn(email: string, password: string, rememberMe: boolean) {

    return signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        if (rememberMe) {
          // Store the user's unique identifier in localStorage
          localStorage.setItem('usertoken', userCredential.user.uid);
        }

        const currentUser = userCredential.user;
        if (currentUser) {
          try {
            const doc = await db.collection("users").doc(currentUser.uid).get();

            if (doc.exists) {
              const authUser: AuthUser = {
                ...currentUser,
                displayName: doc.data()?.displayName || '',
                role: doc.data()?.role || '',
                teamId: doc.data()?.teamId || '',
                language: doc.data()?.language || ''
              };

              setUser(authUser);
              storeUserData(authUser);
            } else {
              console.error('User document not found in Firestore');
            }
          } catch (error) {
            console.error('Error fetching user data from Firestore:', error);
          }
        }
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  }
  function logInWithToken(token: string) {
    return signInWithCustomToken(auth, token)
      .then(async (userCredential) => {
        const currentUser = userCredential.user;
        if (currentUser) {
          try {
            const doc = await db.collection("users").doc(currentUser.uid).get();

            if (doc.exists) {
              const authUser: AuthUser = {
                ...currentUser,
                displayName: doc.data()?.displayName || '',
                role: doc.data()?.role || '',
                teamId: doc.data()?.teamId || '',
                language: doc.data()?.language || ''
              };

              setUser(authUser);
              storeUserData(authUser);
            } else {
              console.error('User document not found in Firestore');
            }
          } catch (error) {
            console.error('Error fetching user data from Firestore:', error);
          }
        }
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  }

  function signUp(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth)
      .then(() => {
        localStorage.removeItem('userData');
        localStorage.removeItem('role');
        localStorage.removeItem('usertoken');
        setUser(null);
      });
  }

  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser) {
        localStorage.removeItem('userData');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <userAuthContext.Provider value={{ user, logIn, logInWithToken, signUp, logOut, googleSignIn }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  const context = useContext(userAuthContext);
  if (!context) {
    throw new Error("useUserAuth must be used within a UserAuthContextProvider");
  }
  return context;
}