export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function customSort<T>(
    getKey: (item: T) => string,
    a: T,
    b: T
): number {
    const keyA = parseInt(getKey(a), 10);
    const keyB = parseInt(getKey(b), 10);

    if (keyA < keyB) {
        return -1;
    }
    if (keyA > keyB) {
        return 1;
    }
    return 0;
}
