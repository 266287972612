import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Container,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { PlayByPlay } from '../typing';
import fetch from 'node-fetch';

interface Team {
  teamName: string;
  gamesPlayed: number;
  teamVictories: number;
  teamLoses: number;
  teamTies: number;
  teamOvertimeLoses: number;
  teamGoalFor: number;
  teamGoalAgainst: number;
  plusMinus: number;
  teamPoints: number;
  teamWinPerc: number;
  sealPointsFor: number;
  sealPointsAgainst: number;
  sealPlusMinus: number;
  goalieSealPointsFor: number;
  goalieSealPointsAgainst: number;
  goalieSealPlusMinus: number;
}

const data: Team[] = [
  {
    teamName: 'A',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }, {
    teamName: 'B',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }, {
    teamName: 'C',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }, {
    teamName: 'D',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }, {
    teamName: 'E',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }, {
    teamName: 'F',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }, {
    teamName: 'G',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }, {
    teamName: 'H',
    gamesPlayed: 0,
    teamVictories: 0,
    teamLoses: 0,
    teamTies: 0,
    teamOvertimeLoses: 0,
    teamGoalFor: 0,
    teamGoalAgainst: 0,
    plusMinus: 0,
    teamPoints: 0,
    teamWinPerc: 0,
    sealPointsFor: 0,
    sealPointsAgainst: 0,
    sealPlusMinus: 0,
    goalieSealPointsFor: 0,
    goalieSealPointsAgainst: 0,
    goalieSealPlusMinus: 0
  }
];

const itemsPerPage = 10;

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const FilterableGrid: React.FC = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Team>('teamName');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleSort = (property: keyof Team) => {
    const newOrderBy = property;
    const newOrder = orderBy === property && order === 'desc' ? 'asc' : 'desc';

    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  const totalPages1 = Math.ceil(sortedData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = async (newPage: number) => {
    //setCurrentPage(newPage);

    const response = await fetch('https://api-web.nhle.com/v1/gamecenter/2023021078/play-by-play');
    const data = await response.json() as PlayByPlay;
    console.log(data);
  };

  const { t, i18n } = useTranslation();

  return (
    <Container>
      <TableContainer component={Paper} style={{ margin: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Équipe</TableCell>
              <TableCell>GP</TableCell>
              <TableCell>V</TableCell>
              <TableCell>L</TableCell>
              <TableCell>T</TableCell>
              <TableCell>OL</TableCell>
              <TableCell>GF</TableCell>
              <TableCell>GA</TableCell>
              <TableCell>+/-</TableCell>
              <TableCell>Pts</TableCell>
              <TableCell>%</TableCell>
              <TableCell>SPF</TableCell>
              <TableCell>SPA</TableCell>
              <TableCell>+/-</TableCell>
              <TableCell>GSPF</TableCell>
              <TableCell>GSPA</TableCell>
              <TableCell>+/-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map(team => (
              <TableRow key={team.teamName}>
                <TableCell>{team.teamName}</TableCell>
                <TableCell>{team.gamesPlayed}</TableCell>
                <TableCell>{team.teamVictories}</TableCell>
                <TableCell>{team.teamLoses}</TableCell>
                <TableCell>{team.teamTies}</TableCell>
                <TableCell>{team.teamOvertimeLoses}</TableCell>
                <TableCell>{team.teamGoalFor}</TableCell>
                <TableCell>{team.teamGoalAgainst}</TableCell>
                <TableCell>{team.plusMinus}</TableCell>
                <TableCell>{team.teamPoints}</TableCell>
                <TableCell>{team.teamWinPerc}</TableCell>
                <TableCell>{team.sealPointsFor}</TableCell>
                <TableCell>{team.sealPointsAgainst}</TableCell>
                <TableCell>{team.sealPlusMinus}</TableCell>
                <TableCell>{team.goalieSealPointsFor}</TableCell>
                <TableCell>{team.goalieSealPointsAgainst}</TableCell>
                <TableCell>{team.goalieSealPlusMinus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        Previous Page
      </Button>
      <Button
        // disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        Next Page
      </Button>

    </Container >
  );
};

export default FilterableGrid;