import React from "react";
import ProtectedRoute from "./ProtectedRoute";

interface UserProtectedRouteProps {
  children: React.ReactNode;
}

const UserProtectedRoute: React.FC<UserProtectedRouteProps> = ({ children }) => {
  return <ProtectedRoute role="user">{children}</ProtectedRoute>;
};

export default UserProtectedRoute;