import React, { useEffect, useState } from 'react';
import {
  Container,
  Select,
  MenuItem,
  Button,
  Box,
  InputLabel
} from '@mui/material';
import { db } from '../../firebaseSetup';
import PlayerTable from '../../components/PlayerTable';
import AddPlayerModal from '../../components/AddNewPlayer';
import { useTranslation } from 'react-i18next';
import { f_Team, Player } from '../../typing';


const TeamsManagement: React.FC = () => {
  const emptyTeam: f_Team = {
    id: '',
    name: '' // Initialize all the fields with default values
    // Initialize other fields as needed
  };
  const { t } = useTranslation();
  const [teams, setTeams] = useState<f_Team[]>([]);
  const [selectedTeamObject, setSelectedTeamObject] = useState<f_Team>(emptyTeam);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [players, setPlayers] = useState<Player[]>([]);
  const [isAddPlayerModalOpen, setIsAddPlayerModalOpen] = useState(false);

  // Fetch the list of teams from Firestore
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsSnapshot = await db.collection('fantasy-teams').get();
        const teamData = teamsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as f_Team[];
        setTeams(teamData);
      } catch (error) {
        console.error('Error fetching teams: ', error);
      }
    };

    fetchTeams();
  }, []);

  // Fetch the list of players for the selected team
  useEffect(() => {
    if (selectedTeam) {
      const fetchPlayers = async () => {
        try {
          const playersSnapshot = await db
            .collection('players')
            .where('fantasyTeamId', '==', selectedTeam)
            .get();
          const playerData = playersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Player[];
          setPlayers(playerData);
        } catch (error) {
          console.error('Error fetching players: ', error);
        }
      };

      fetchPlayers();
    } else {
      // Clear the players list if no team is selected
      setPlayers([]);
    }
  }, [selectedTeam]);

  const handleTeamChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const teamId = event.target.value as string;
    setSelectedTeam(teamId); // Update selectedTeam state

    // Find the selected team object based on the teamId
    const teamObject = teams.find((team) => team.id === teamId) ?? emptyTeam;

    // Update the selectedTeamObject state
    setSelectedTeamObject(teamObject);
  };


  const handleAddPlayer = () => {
    setIsAddPlayerModalOpen(true);
  };

  const handleOnEditPlayer = async (player: Player, newPosition: string) => {
    // Implement the logic to remove a player from the selected team
    // You can show a confirmation dialog and then remove the player from Firestore.
    try {
      // Fetch the selected player document from Firestore
      const playerDocRef = db.collection('players').doc(player.id);
      const playerDoc = await playerDocRef.get();

      if (playerDoc.exists) {
        // Update the fantasyTeamId field of the player document
        await playerDocRef.update({
          position: newPosition,
        });

        const updatedPlayers = [...players];

        const playerIndex = updatedPlayers.findIndex((oldplayer) => oldplayer.id === player.id);

        if (playerIndex !== -1) {
          // Update the position of the player at the found index
          updatedPlayers[playerIndex].position = newPosition;

          // Update the state with the modified array
          setPlayers(updatedPlayers);
        }
      } else {
        console.error('Player document does not exist.');
      }
    } catch (error) {
      console.error('Error updating player: ', error);
    }
  };

  const handleM25Tag = async (player: Player, isM25: boolean) => {
    try {

      const playerDocRef = db.collection('players').doc(player.id);
      const playerDoc = await playerDocRef.get();

      if (playerDoc.exists) {
        await playerDocRef.update({
          isM25: isM25,
        });

        const updatedPlayers = [...players];

        const playerIndex = updatedPlayers.findIndex((oldplayer) => oldplayer.id === player.id);

        if (playerIndex !== -1) {
          updatedPlayers[playerIndex].isM25 = isM25;

          setPlayers(updatedPlayers);
        }
      } else {
        console.error('Player document does not exist.');
      }
    } catch (error) {
      console.error('Error updating player: ', error);
    }
  };

  const handleRemovePlayer = async (playerId: string) => {
    try {
      const playerDocRef = db.collection('players').doc(playerId);
      const playerDoc = await playerDocRef.get();

      if (playerDoc.exists) {
        await playerDocRef.update({
          fantasyTeamId: "",
        });

        const updatedPlayers = players.filter((player) => player.id !== playerId);
        setPlayers(updatedPlayers);
      } else {
        console.error('Player document does not exist.');
      }
    } catch (error) {
      console.error('Error updating player: ', error);
    }
  };

  const handleAddPlayerConfirm = async (selectedTeamId: string, selectedPlayer: Player) => {
    try {
      const playerDocRef = db.collection('players').doc(selectedPlayer.id);
      const playerDoc = await playerDocRef.get();

      if (playerDoc.exists) {
        await playerDocRef.update({
          fantasyTeamId: selectedTeamId,
        });

        const updatedPlayers = [...players, selectedPlayer];
        setPlayers(updatedPlayers);
        setIsAddPlayerModalOpen(false);
      } else {
        console.error('Player document does not exist.');
      }

    } catch (error) {
      console.error('Error updating player: ', error);
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingX={{ xs: '16px', sm: '32px' }}
        paddingY={{ xs: '5px', sm: '10px' }} // Adjust the padding as needed
      >
        <InputLabel id="select-label">{t("SelectTeam")}</InputLabel>
        <Select labelId="select-label"
          label={t("SelectTeam")}
          value={selectedTeam}
          onChange={handleTeamChange as any}
          fullWidth
          sx={{ marginTop: '4px' }}
        >
          {teams.map((team) => (
            <MenuItem key={team.id} value={team.id}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selectedTeam && (
        <>
          <Box display="flex" justifyContent="flex-end" mt={1} paddingY={{ xs: '5px', sm: '10px' }} paddingX={{ xs: '16px', sm: '32px' }}>
            <Button variant="contained" color="primary" onClick={handleAddPlayer}>
              {t("AddPlayer")}
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" paddingY={{ xs: '5px', sm: '10px' }} paddingX={{ xs: '0px', sm: '8px' }}>
            <PlayerTable
              players={players}
              onChangePosition={handleOnEditPlayer}
              onRemovePlayer={handleRemovePlayer}
              onIsM25={handleM25Tag}
            />
          </Box>
        </>
      )}
      <AddPlayerModal
        open={isAddPlayerModalOpen}
        onClose={() => setIsAddPlayerModalOpen(false)} // Close the modal
        team={selectedTeamObject}
        onAddPlayer={handleAddPlayerConfirm}
      />
    </Container>
  );
};

export default TeamsManagement;