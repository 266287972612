import React, { useEffect, useState } from 'react';
import { Container, Grid, CircularProgress, Typography, Box, Divider } from '@mui/material';
import GameCard from '../components/GameCard';
import { useTranslation } from 'react-i18next';
import { db } from '../firebaseSetup';
import { Matchup, Settings } from '../typing';

const SchedulePage: React.FC = () => {
  const { t } = useTranslation();
  const [matchups, setMatchups] = useState<Matchup[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState<Settings>();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        // Fetch settings first
        const settingsDocRef = await db.collection('current-settings').doc('default');
        const settingsDoc = await settingsDocRef.get();

        if (settingsDoc.exists) {
          const settingsData = {
            id: settingsDoc.id,
            ...settingsDoc.data(),
          } as Settings;

          setSettings(settingsData);

          const matchupsSnapshot = await db
            .collection('matchups')
            .where('seasonId', '==', settingsData?.seasonId)
            .get();

          const matchupsData = matchupsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Matchup[];
          setMatchups(matchupsData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    setIsLoading(false);
  }, []);

  const matchupsByWeekAndGame: Record<string, Record<string, Matchup[]>> = matchups.reduce((acc, matchup) => {
    const { fantasyWeekId, gameNumber } = matchup;

    // Create a new week object if it doesn't exist in the accumulator
    if (!acc[fantasyWeekId]) {
      // Use type assertion here to tell TypeScript the expected structure
      acc[fantasyWeekId] = {};
    }

    // Create a new game number object if it doesn't exist in the week object
    if (!acc[fantasyWeekId][gameNumber]) {
      // Use type assertion here to tell TypeScript the expected structure
      acc[fantasyWeekId][gameNumber] = [] as Matchup[];
    }

    // Push the matchup into the corresponding game number
    acc[fantasyWeekId][gameNumber].push(matchup);

    return acc;
  }, {} as Record<string, Record<string, Matchup[]>>);

  return (
    <Container>
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Box
          paddingY={{ xs: '5px', sm: '10px' }}
          paddingX={{ xs: '5pxpx', sm: '10px' }}
        >
          <Typography variant="h6">Season - {settings?.seasonId}</Typography>

          <Box
            paddingY={{ xs: '5px', sm: '10px' }}
            paddingX={{ xs: '5pxpx', sm: '10px' }}
          >
            <Typography variant="h6">Matchups</Typography>
            {Object.entries(matchupsByWeekAndGame).map(([week, gameData]) => (
              <div key={week}>
                <Divider>Week {week}</Divider>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {Object.entries(gameData).map(([gameNumber, matchups]) => (
                    <Grid key={gameNumber} item xs={12} md={12}>
                      <Typography>Game # {gameNumber}</Typography>
                      {matchups.map((matchup) => (
                        <Grid key={matchup.id} item style={{ padding: '5px' }}>
                          <GameCard key={matchup.id} matchup={matchup} />
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </Box>
        </Box >
      )}
    </Container>
  );
};

export default SchedulePage;