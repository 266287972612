import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="App">
      <header className="App-header">
        <p>
          {t('welcome')}
        </p>
      </header>
    </div>
  );
};

export default Home;