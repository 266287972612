import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { useUserAuth } from '../context/UserAuthContext'; // Import your user authentication context
import { db } from '../firebaseSetup'; // Import your Firestore setup
import { useTranslation } from 'react-i18next';

const UserPreferences: React.FC = () => {
  const { user } = useUserAuth();
  const [name, setName] = useState<string>('');
  const [language, setLanguage] = useState<string>('fr');
  const [buttonColor, setButtonColor] = useState<'primary' | 'success' | 'error'>('primary');
  const { t, i18n } = useTranslation();


  useEffect(() => {
    if (user) {
      // Create a reference to the user's document in the Firestore "users" collection
      const userDocRef = db.collection('users').doc(user.uid);

      // Fetch the user's document from Firestore
      userDocRef
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const userData = doc.data();
            // Set the user's preferences if available
            if (userData) {
              setName(userData.displayName || '');
              setLanguage(userData.language || '');
            }
          }
        })
        .catch((error: Error) => {
          console.error('Error fetching user data from Firestore:', error);
        });
    }
  }, [user]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleLanguageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedLanguage = e.target.value as string;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage.toLowerCase()); // Change the language in i18n
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Update user preferences in Firestore
    if (user) {
      const userDocRef = db.collection('users').doc(user.uid);
      userDocRef.set({
        name,
        language,
      }, { merge: true }) // Merge with existing data, if any
        .then(() => {
          setButtonColor('success'); // Set button color to success
          setTimeout(() => {
            setButtonColor('primary'); // Reset button color to primary after 5 seconds
          }, 5000);
        })
        .catch((error: Error) => {
          console.error('Error updating user preferences:', error);
          setButtonColor('error'); // Set button color to error
          setTimeout(() => {
            setButtonColor('primary'); // Reset button color to primary after 5 seconds
          }, 5000);
        });
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        {t("User Preferences")}
      </Typography>
      <FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("Name")}
              variant="outlined"
              value={name}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-language-label">{t("Language")}</InputLabel>
              <Select
                labelId="select-language-label"
                id="select-language"
                value={language}
                onChange={handleLanguageChange as any}
                label="Language"
              >
                <MenuItem value={'EN'}>EN</MenuItem>
                <MenuItem value={'FR'}>FR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleSubmit} type="submit" variant="contained" color={buttonColor}>
              {t("Save Preferences")}
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Container>
  );
};

export default UserPreferences;