import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import './firebaseSetup';
import "bootstrap/dist/css/bootstrap.min.css";
import { UserAuthContextProvider } from "./context/UserAuthContext";

i18n
  .init({
    resources: {
      en: { translation: require('./locales/en/translation.json') },
      fr: { translation: require('./locales/fr/translation.json') }
    },
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <I18nextProvider i18n={i18n}>
    <UserAuthContextProvider>
      <App />
    </UserAuthContextProvider>
  </I18nextProvider>
);