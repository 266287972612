import React, { useEffect, useState } from 'react';
import {
  Switch,
  Select,
  MenuItem,
  TextField,
  CardContent,
  Card,
  Grid,
  Container,
  Typography
} from '@mui/material';
import { useUserAuth } from '../../context/UserAuthContext';
import { db } from '../../firebaseSetup';
import { useTranslation } from 'react-i18next';
import { User, Team } from '../../typing';

function UserManagementPage() {
  const { user } = useUserAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);

  const fetchUserData = async () => {
    try {
      // Fetch user data
      const userSnapshot = await db.collection("users").get();
      const userData = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as User[];
      setUsers(userData);

      // Fetch teams
      const teamSnapshot = await db.collection("fantasy-teams").get();
      const teamData = teamSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Team[];
      setTeams(teamData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleDisplayNameChange = async (userId: string, newName: string) => {
    try {
      const updatedUsers = users.map((u) =>
        u.id === userId ? { ...u, displayName: newName } : u
      );
      setUsers(updatedUsers);
      await db.collection("users").doc(userId).update({ displayName: newName });
    } catch (error) {
      console.error("Error updating role: ", error);
    }
  };
  const handleRoleChange = async (userId: string, newRole: string) => {
    try {
      const updatedUsers = users.map((u) =>
        u.id === userId ? { ...u, role: newRole } : u
      );
      setUsers(updatedUsers);
      await db.collection("users").doc(userId).update({ role: newRole });
    } catch (error) {
      console.error("Error updating role: ", error);
    }
  };

  const handleTeamChange = async (userId: string, newTeamId: string) => {
    try {
      const updatedUsers = users.map((u) =>
        u.id === userId ? { ...u, teamId: newTeamId } : u
      );
      setUsers(updatedUsers);
      await db.collection("users").doc(userId).update({ teamId: newTeamId });
    } catch (error) {
      console.error("Error updating team: ", error);
    }
  };

  const handleToggleState = async (user: User, newState: boolean) => {
    try {
      const updatedUsers = users.map((u) =>
        u.id === user.id ? { ...u, state: newState } : u
      );
      setUsers(updatedUsers);
      await db.collection("users").doc(user.id).update({ state: newState });
    } catch (error) {
      console.error("Error toggling state: ", error);
    }
  };

  const isAdmin = user && user.role === 'admin';

  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {users.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{user.displayName}</Typography>
                <Typography>{t("Email")}: {user.email}</Typography>
                {isAdmin ? (<Select
                  value={user.role}
                  onChange={(e) =>
                    handleRoleChange(user.id, e.target.value as string)
                  }
                >
                  <MenuItem value="admin">{t("Admin")}</MenuItem>
                  <MenuItem value="user">{t("User")}</MenuItem>
                  <MenuItem value="none">{t("None")}</MenuItem>
                </Select>) : (<Typography>{t("Role")}: {user.role}</Typography>)}
                {isAdmin ? (<Select
                  value={user.teamId}
                  onChange={(e) =>
                    handleTeamChange(user.id, e.target.value as string)
                  }
                >
                  {teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>) : (<Typography>{t("Team")}: {user.teamId}</Typography>)}

                <Switch
                  checked={user.state}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    handleToggleState(user, e.target.checked)
                  }
                />
                {isAdmin ? (<TextField
                  value={user.displayName}
                  onChange={(e) =>
                    handleDisplayNameChange(user.id, e.target.value as string)
                  }
                />) : (<></>)}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default UserManagementPage;