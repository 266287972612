import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";

interface ProtectedRouteProps {
  children: React.ReactNode;
  role: "admin" | "user" | "none";
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, role }) => {
  const { user, logIn } = useUserAuth();
  const storedUserData = localStorage.getItem('userData');
// If there's no user data in both user and localStorage, redirect to the login page
if (!user && !storedUserData) {
  return <Navigate to="/" />;
}

// If user data is not available in the 'user' object but exists in localStorage, set the user
if (!user && storedUserData) {
  const userData = JSON.parse(storedUserData);
    try {
     logIn(userData.email, userData.password, true)      
    } catch (err: any) {
      console.log(err.message);
    }
}

  // Check if the user's role matches the required role or is an admin
  if (user &&
    user.role === "admin" ||
    (role === "user" && (user && (user.role === "user" || user.role === "admin"))) ||
    (role === "none" && (user && (user.role === "none" || user.role === "user" || user.role === "admin")))
  ) {
    return <>{children}</>;
  }
  else{
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;