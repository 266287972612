import React from "react";
import ProtectedRoute from "./ProtectedRoute";

interface AdminProtectedRouteProps {
  children: React.ReactNode;
}

const AdminProtectedRoute: React.FC<AdminProtectedRouteProps> = ({ children }) => {
  return <ProtectedRoute role="admin">{children}</ProtectedRoute>;
};

export default AdminProtectedRoute;