import { Avatar, Button, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import * as React from 'react';
import { Player } from '../typing';
import { Draggable } from 'react-beautiful-dnd';
import "../styles.css";

export type DraggableListItemProps = {
    item: Player;
    index: number;
    onAddRookie: (player: Player) => void;
};

const DraggableListItem = ({ item, index, onAddRookie }: DraggableListItemProps) => {

    const handleAddRookie = (player: Player) => {
        onAddRookie(player);
    };

    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided: any, snapshot: any) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? 'draggingListItem' : ''}
                >
                    <ListItemAvatar>
                        <Avatar>{index + 1}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.fullName} secondary={`${item.position} ${item.isM25 ? '(M25)' : ''}`} />
                    <Button onClick={() => handleAddRookie(item)}>Add to prospects</Button>
                </ListItem>
            )}
        </Draggable>
    );
};

export default DraggableListItem;
