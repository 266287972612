import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserAuth } from '../context/UserAuthContext';
import { db } from '../firebaseSetup';
import { CompleteGoalieGameStats, Lineup, LineupStats, Matchup, Player, PlayerGameStats, Settings, Week } from '../typing';
import DraggableList from '../components/DraggableList';
import { customSort, reorder } from '../helpers';
import { DropResult } from 'react-beautiful-dnd';

const Team: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUserAuth();

  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<{ [position: string]: string[] }>({
    LW: ['', '', ''],
    C: ['', '', ''],
    RW: ['', '', ''],
    D1: ['', '', ''],
    D2: ['', '', ''],
    G: ['', '', '', ''],
  });
  const [rookies, setRookies] = useState<string[]>([]);
  const [benchedPlayers, setBenchedPlayers] = useState<Player[]>([]);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [lineupId, setLineupId] = useState<string>('');
  const [weeks, setWeeks] = useState<Week[]>([]);
  const [settings, setSettings] = useState<Settings>();
  const [nextWeek, setNextWeek] = useState<string>('');

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(benchedPlayers, source.index, destination.index);
    setBenchedPlayers(newItems);
  };

  useEffect(() => {
    if (user) {
      //setCurrentNextWeek();

      const settingsDocRef = db.collection('current-settings').doc('default');
      settingsDocRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const settingsData = {
              id: doc.id,
              ...doc.data(),
            } as Settings;

            setSettings(settingsData);
          }
        })
        .catch((error: Error) => {
          console.error('Error fetching settings data from Firestore:', error);
        });

      let weekNumber = 0;
      if (settings?.fantasyWeekNumber) {
        weekNumber = +settings?.fantasyWeekNumber;
        setNextWeek((weekNumber + 1).toString());
      }



      const fetchPlayers = async () => {
        try {
          const playersSnapshot = await db
            .collection('players')
            .where('fantasyTeamId', '==', user.teamId)
            .get();
          const playerData = playersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Player[];
          setPlayers(playerData);

          db.collection('fantasy-lineup')
            .where('teamId', '==', user.teamId)
            .get()
            .then(async (querySnapshot: any) => {
              if (!querySnapshot.empty) {
                const firstDocument = querySnapshot.docs[0];
                const lineupData = firstDocument.data() as Lineup;

                setSelectedPlayers({
                  ...selectedPlayers,
                  C: lineupData.c,
                  LW: lineupData.lw,
                  RW: lineupData.rw,
                  D1: lineupData.d1,
                  D2: lineupData.d2,
                  G: lineupData.g
                });


                const selectedPlayerIdsArray = [
                  ...lineupData.c,
                  ...lineupData.lw,
                  ...lineupData.rw,
                  ...lineupData.d1,
                  ...lineupData.d2,
                  ...lineupData.g,
                  ...lineupData.rookies
                ];
                setLineupId(firstDocument.id);

                let bench: Player[] = [];
                if (selectedPlayerIdsArray.length === 0 && lineupData.bench.length === 0) {
                  bench = playerData;
                }
                else {
                  bench = lineupData.bench
                    .map((benchPlayerId) => playerData.find((player) => player.id === benchPlayerId)) as Player[];
                }

                setBenchedPlayers(bench);

                setRookies(lineupData.rookies);
              } else {

                const bench = playerData.map(player => player.id);

                const newLineupData: Lineup = {
                  teamId: user.teamId,
                  c: ['', '', ''],
                  lw: ['', '', ''],
                  rw: ['', '', ''],
                  d1: ['', '', ''],
                  d2: ['', '', ''],
                  g: ['', '', ''],
                  rookies: [],
                  bench: bench,
                  isTemplate: true,
                };


                const newLineupRef = await db.collection('fantasy-lineup').add(newLineupData);
                setLineupId(newLineupRef.id);
              }
            })
            .catch((error: Error) => {
              console.error('Error fetching user data from Firestore:', error);
            });


        } catch (error) {
          console.error('Error fetching players: ', error);
        }
      };

      fetchPlayers();
    }
  }, []);

  const handlePlayerSelect = async (position: string, index: number, playerId: string) => {
    // Check if the player is already selected for the position
    if (selectedPlayers[position].includes(playerId)) {
      setErrorState(true);
      return; // Player is already selected, no need to update
    }

    setErrorState(false);

    const updatedSelectedPlayers = { ...selectedPlayers };
    updatedSelectedPlayers[position][index] = playerId;
    setSelectedPlayers(updatedSelectedPlayers);
    const updatedBenchedPlayers = benchedPlayers.filter((player) => player.id !== playerId);
    setBenchedPlayers(updatedBenchedPlayers);
  };


  const transformToLineupStats = (lineup: Lineup): LineupStats => {

    let centers: PlayerGameStats[] = [];
    lineup.c.forEach((id) => {
      let player = players.find((player) => player.id === id);
      centers.push({
        playerId: id,
        playerName: player?.fullName,
        g: 0,
        ppg: 0,
        shg: 0,
        a: 0,
        ppa: 0,
        sha: 0,
        penaltyMinutes: 0
      } as PlayerGameStats);
    });

    let rws: PlayerGameStats[] = [];
    lineup.rw.forEach((id) => {
      let player = players.find((player) => player.id === id);
      rws.push({
        playerId: id,
        playerName: player?.fullName,
        g: 0,
        ppg: 0,
        shg: 0,
        a: 0,
        ppa: 0,
        sha: 0,
        penaltyMinutes: 0
      } as PlayerGameStats);
    });

    let lws: PlayerGameStats[] = [];
    lineup.lw.forEach((id) => {
      let player = players.find((player) => player.id === id);
      lws.push({
        playerId: id,
        playerName: player?.fullName,
        g: 0,
        ppg: 0,
        shg: 0,
        a: 0,
        ppa: 0,
        sha: 0,
        penaltyMinutes: 0
      } as PlayerGameStats);
    });

    let def1: PlayerGameStats[] = [];
    lineup.d1.forEach((id) => {
      let player = players.find((player) => player.id === id);
      def1.push({
        playerId: id,
        playerName: player?.fullName,
        g: 0,
        ppg: 0,
        shg: 0,
        a: 0,
        ppa: 0,
        sha: 0,
        penaltyMinutes: 0
      } as PlayerGameStats);
    });

    let def2: PlayerGameStats[] = [];
    lineup.d2.forEach((id) => {
      let player = players.find((player) => player.id === id);
      def2.push({
        playerId: id,
        playerName: player?.fullName,
        g: 0,
        ppg: 0,
        shg: 0,
        a: 0,
        ppa: 0,
        sha: 0,
        penaltyMinutes: 0
      } as PlayerGameStats);
    });

    let bench: PlayerGameStats[] = [];
    lineup.bench.forEach((id) => {
      let player = players.find((player) => player.id === id);
      bench.push({
        playerId: id,
        playerName: player?.fullName,
        g: 0,
        ppg: 0,
        shg: 0,
        a: 0,
        ppa: 0,
        sha: 0,
        penaltyMinutes: 0
      } as PlayerGameStats);
    });

    let goalies: CompleteGoalieGameStats[] = [];
    lineup.g.forEach((id) => {
      if (id !== '' && id !== undefined) {
        let player = players.find((player) => player.id === id);
        goalies.push({
          playerId: id,
          playerName: player?.fullName ?? '',
          stats: [
            {
              periodId: '1',
              shots: 0,
              goals: 0,
              savePercentage: 0
            }, {
              periodId: '2',
              shots: 0,
              goals: 0,
              savePercentage: 0
            }, {
              periodId: '3',
              shots: 0,
              goals: 0,
              savePercentage: 0
            }, {
              periodId: 'total',
              shots: 0,
              goals: 0,
              savePercentage: 0
            }
          ]
        });
      }
    });

    const c = centers.map((obj) => { return Object.assign({}, obj) });
    const rw = rws.map((obj) => { return Object.assign({}, obj) });
    const lw = lws.map((obj) => { return Object.assign({}, obj) });
    const d1 = def1.map((obj) => { return Object.assign({}, obj) });
    const d2 = def2.map((obj) => { return Object.assign({}, obj) });
    const g = goalies.map((obj) => { return Object.assign({}, obj) });
    const b = bench.map((obj) => { return Object.assign({}, obj) });

    let lineupStats: LineupStats = {
      c: c,
      rw: rw,
      lw: lw,
      d1: d1,
      d2: d2,
      g: g,
      bench: b
    };
    return lineupStats;
  };

  const setLineupForNextWeek = async () => {

    const weeksref = await db.collection('fantasy-weeks').where('seasonId', '==', settings?.seasonId).get();

    const weeksData = weeksref.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Week[];

    weeksData.sort((a, b) => customSort((item) => item.number, a, b));

    let weekNumber = 0;
    if (settings?.fantasyWeekNumber) {
      weekNumber = +settings?.fantasyWeekNumber;
    }
    const currentWeek = weeksData.find((week) => week.number === weekNumber.toString());
    const nextWeek = weeksData.find((week) => week.number === (weekNumber + 1).toString());

    const lineupDoc = await db.collection('fantasy-lineup').doc(lineupId).get();
    const lineupData = lineupDoc.data() as Lineup;

    const matchupsRef = await db.collection('matchups')
      .where('seasonId', '==', settings?.seasonId)
      .where('fantasyWeekId', '==', settings?.fantasyWeekId)
      .get();

    const matchupsData = matchupsRef.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Matchup[];


    const updatedTeam = transformToLineupStats(lineupData);

    matchupsData.forEach((matchup) => {

      if (matchup.homeTeamId === user?.teamId) {
        const partialUpdate =
        {
          homeTeam: updatedTeam
        };

        db.collection('matchups')
          .doc(matchup.id)
          .set(partialUpdate, { merge: true })
          .then(() => {
            console.log('Matchup home team updated successfully.');
          })
          .catch((error) => {
            console.error('Error updating matchup home team:', error);
          });
      }

      if (matchup.awayTeamId === user?.teamId) {
        const partialUpdate =
        {
          awayTeam: updatedTeam
        };
        db.collection('matchups')
          .doc(matchup.id)
          .set(partialUpdate, { merge: true })
          .then(() => {
            console.log('Matchup away team updated successfully.');
          })
          .catch((error) => {
            console.error('Error updating matchup away team:', error);
          });
      }
    });
  };

  const updateTable = () => {
    const benchedPlayerIds = benchedPlayers.map((player) => player.id);
    let modifiedLineup: Lineup = {
      c: selectedPlayers['C'],
      lw: selectedPlayers['LW'],
      rw: selectedPlayers['RW'],
      rookies: rookies,
      bench: benchedPlayerIds,
      d1: selectedPlayers['D1'],
      d2: selectedPlayers['D2'],
      g: selectedPlayers['G'],
      isTemplate: true,
      teamId: user?.teamId as string
    };

    if (lineupId) {
      const lineupRef = db.collection('fantasy-lineup').doc(lineupId);
      lineupRef
        .update(modifiedLineup)
        //.set(modifiedLineup, { merge: true })
        .then(() => {

        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
    }
  };

  const isPlayerSelected = (position: string, playerId: string) => {
    return selectedPlayers[position].includes(playerId);
  };

  useEffect(() => {
    updateTable();
  }, [rookies, benchedPlayers, selectedPlayers]);

  const addToRookies = (player: Player) => {
    const updatedRookies = [...rookies, player.id];
    setRookies(updatedRookies);
    const updatedBenchedPlayers = benchedPlayers.filter((benchedPlayer) => benchedPlayer.id !== player.id);
    setBenchedPlayers(updatedBenchedPlayers);
  };

  const removeFromRookies = (player: Player) => {
    const updatedRookies = rookies.filter((rookie) => rookie !== player.id);
    setRookies(updatedRookies);
    const updatedBenchedPlayers = [...benchedPlayers, player];
    setBenchedPlayers(updatedBenchedPlayers);
  };
  //box/card/box/card
  return (
    <Container>
      {
        <Button onClick={setLineupForNextWeek}>Set lineup for week {nextWeek}</Button>
      }
      <Box display="flex" flexWrap="wrap"
        paddingY={{ xs: '5px', sm: '10px' }}

      >
        <Card>
          <Typography variant='h6' align='center'>Active Team</Typography>
          <Box display="flex" flexWrap="wrap" >
            <Card style={{ flex: '1', margin: '16px', minWidth: '350px' }}>
              <CardContent>
                <Typography variant="h6">{t('LW')}</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedPlayers['LW'].map((selectedPlayerId, index) => (
                    <FormControl key={index} variant="outlined" style={{ width: '100%', margin: '5px' }}>
                      <InputLabel id={`select-label-LW-${index + 1}`}>{`${index + 1}`}</InputLabel>
                      <Select
                        labelId={`select-label-LW-${index + 1}`}
                        label={`${index + 1}`}
                        value={selectedPlayerId}
                        onChange={(event) => handlePlayerSelect('LW', index, event.target.value as string)}
                      ><MenuItem key={`lw-${index + 1}`} value="">
                          Select a player
                        </MenuItem>
                        {players
                          .filter((player) => player.position === 'LW')
                          .map((player) => (
                            <MenuItem key={player.id} value={player.id}>
                              {`${player.fullName} ${player.isM25 ? '(M25)' : ''}`}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card style={{ flex: '1', margin: '16px', minWidth: '350px' }}>
              <CardContent>
                <Typography variant="h6">{t('C')}</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedPlayers['C'].map((selectedPlayerId, index) => (
                    <FormControl key={index} variant="outlined" style={{ width: '100%', margin: '5px' }}>
                      <InputLabel id={`select-label-C-${index + 1}`}>{`${index + 1}`}</InputLabel>
                      <Select
                        labelId={`select-label-C-${index + 1}`}
                        label={`${index + 1}`}
                        value={selectedPlayerId}
                        onChange={(event) => handlePlayerSelect('C', index, event.target.value as string)}
                      ><MenuItem key={`c-${index + 1}`} value="">
                          Select a player
                        </MenuItem>
                        {players
                          .filter((player) => player.position === 'C')
                          .map((player) => (
                            <MenuItem key={player.id} value={player.id}>
                              {`${player.fullName} ${player.isM25 ? '(M25)' : ''}`}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  ))}
                </div>
              </CardContent>
            </Card>
            <Card style={{ flex: '1', margin: '16px', minWidth: '350px' }}>
              <CardContent>
                <Typography variant="h6">{t('RW')}</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedPlayers['RW'].map((selectedPlayerId, index) => (
                    <FormControl key={index} variant="outlined" style={{ width: '100%', margin: '5px' }}>
                      <InputLabel id={`select-label-RW-${index + 1}`}>{`${index + 1}`}</InputLabel>
                      <Select
                        labelId={`select-label-RW-${index + 1}`}
                        label={`${index + 1}`}
                        value={selectedPlayerId}
                        onChange={(event) => handlePlayerSelect('RW', index, event.target.value as string)}
                      >
                        <MenuItem key={`rw-${index + 1}`} value="">
                          Select a player
                        </MenuItem>
                        {players
                          .filter((player) => player.position === 'RW')
                          .map((player) => (
                            <MenuItem key={player.id} value={player.id}>
                              {`${player.fullName} ${player.isM25 ? '(M25)' : ''}`}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  ))}
                </div>
              </CardContent>
            </Card>
            <Card style={{ flex: '1', margin: '16px', minWidth: '350px' }}>
              <CardContent>
                <Typography variant="h6">D</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedPlayers['D1'].map((selectedPlayerId, index) => (
                    <FormControl key={index} variant="outlined" style={{ width: '100%', margin: '5px' }}>
                      <InputLabel id={`select-label-D1-${index + 1}`}>{`${index + 1}`}</InputLabel>
                      <Select
                        labelId={`select-label-D1-${index + 1}`}
                        label={`${index + 1}`}
                        value={selectedPlayerId}
                        onChange={(event) => handlePlayerSelect('D1', index, event.target.value as string)}
                      >
                        <MenuItem key={`d1-${index + 1}`} value="">
                          Select a player
                        </MenuItem>
                        {players
                          .filter((player) => player.position === 'D')
                          .map((player) => (
                            <MenuItem key={player.id} value={player.id}>
                              {`${player.fullName} ${player.isM25 ? '(M25)' : ''}`}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  ))}
                </div>
              </CardContent>
            </Card>
            <Card style={{ flex: '1', margin: '16px', minWidth: '350px' }}>
              <CardContent>
                <Typography variant="h6">{t('D')}</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedPlayers['D2'].map((selectedPlayerId, index) => (
                    <FormControl key={index} variant="outlined" style={{ width: '100%', margin: '5px' }}>
                      <InputLabel id={`select-label-D2-${index + 1}`}>{`${index + 1}`}</InputLabel>
                      <Select
                        labelId={`select-label-D2-${index + 1}`}
                        label={`${index + 1}`}
                        value={selectedPlayerId}
                        onChange={(event) => handlePlayerSelect('D2', index, event.target.value as string)}
                      ><MenuItem key={`d2-${index + 1}`} value="">
                          Select a player
                        </MenuItem>
                        {players
                          .filter((player) => player.position === 'D')
                          .map((player) => (
                            <MenuItem key={player.id} value={player.id}>
                              {`${player.fullName} ${player.isM25 ? '(M25)' : ''}`}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card style={{ flex: '1', margin: '16px', minWidth: '350px' }}>
              <CardContent>
                <Typography variant="h6">{t('G')}</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selectedPlayers['G'].map((selectedPlayerId, index) => (
                    <FormControl key={index} variant="outlined" style={{ width: '100%', margin: '5px' }}>
                      <InputLabel id={`select-label-G-${index + 1}`}>{`${index + 1}`}</InputLabel>
                      <Select
                        labelId={`select-label-G-${index + 1}`}
                        label={`${index + 1}`}
                        value={selectedPlayerId}
                        onChange={(event) => handlePlayerSelect('G', index, event.target.value as string)}
                      ><MenuItem key={`g-${index + 1}`} value="">
                          Select a player
                        </MenuItem>
                        {players
                          .filter((player) => player.position === 'G')
                          .map((player) => (
                            <MenuItem key={player.id} value={player.id}>
                              {`${player.fullName} ${player.isM25 ? '(M25)' : ''}`}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Box>
        </Card>
      </Box>
      <Box display="flex" flexWrap="wrap" margin='16'>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          <Card style={{ flex: '1', minWidth: '300px', padding: '16px' }}>
            <Typography variant='h6' align='center'>Bench</Typography>
            <DraggableList items={benchedPlayers} onDragEnd={onDragEnd} onAddRookie={addToRookies} />
          </Card>
          <Card style={{ flex: '1', minWidth: '300px', padding: '16px' }}>
            <Typography variant='h6' align='center'>Prospects</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Position</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {players
                    .filter((player) => rookies.includes(player.id))
                    .map((player) => (
                      <TableRow key={player.id}>
                        <TableCell>
                          <>{player.fullName}</>
                          {player.isM25 ? <Chip label="M25" size="small" style={{ margin: '10px' }} /> : null}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>{player.position}</TableCell>
                        <TableCell>
                          <Button onClick={() => removeFromRookies(player)}>Remove</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </Box>
    </Container >
  );
};

export default Team;